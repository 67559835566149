import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'antd';
import "../css/rahul-styles.css"

const PopupVideo = (props) => {

  const {videoURL, time, handleTimeChange, designType} = props 




  const [isModalVisible, setIsModalVisible] = useState(false);
 

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
      setIsModalVisible(false);
      document.getElementById("iframeYouTubeVideo").src=videoURL;
  };

 




  return (
    <>

{ designType == "New" ? (
  
  <a onClick={showModal} class="popup-youtube absolute-middle-center video-icon-box video-play-icon-style-2">
  <span><img src="https://assets.theworldgrad.com/wp-content/uploads/2023/07/play-white-icon.png" alt="play" /></span> Watch video
</a>
) : (
  <a onClick={showModal} class="popup-youtube absolute-middle-center video-icon-box video-play-icon-style-2 video-play-icon-only video-icon-large">
     <span><img src="https://assets.theworldgrad.com/wp-content/uploads/2023/08/play-3.png" alt="play" /></span>
  </a>
) }

      <Modal className="antd-modal-main"  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000} footer={null} >
      <div class="container newStyle video-container ">
      <iframe id="iframeYouTubeVideo" class="responsive-iframe" width="100%" height="100%" src={videoURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      
      </div>
      </Modal>
    </>
  )
}

export default PopupVideo
