import React from "react";
import Swiper from "swiper";
import PopupVideo from "../components/popupVideo";


const TestimonialSlider = ({ testimonials }) => {
  React.useEffect(() => {
    var swiper = new Swiper('.testimonial-slider', {
              centeredSlides: false,
              loop: false,
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              autoplay: {
                delay: 7000,
                disableOnInteraction: false
              },
              pagination: {
                el: '.swiper-pagination-testimonial',
                clickable: true,
              },
              navigation: {
                nextEl: '.swiper-next-testimonial',
                prevEl: '.swiper-prev-testimonial',
              },
              
              breakpoints: {
                1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20},
                991: { slidesPerGroup: 2, slidesPerView: 2},
                768: { slidesPerGroup: 1, slidesPerView: 1 }
              }
            });




  }, []);

  

  return (

              <div className="swiper-container swiper-style-new-design testimonial-slider">
                <div className="swiper-wrapper">
                {testimonials.map(({ node }, index) => (
  <div key={node.wordpress_id} className="swiper-slide desktop">
    {node.acf.youtube_video_link && (
      <PopupVideo
        time="9999999999"
        videoURL={node.acf.youtube_video_link}
      />
    )}
    {node.acf.small_image?.source_url && ( // Optional chaining to ensure small_image exists
      <img
        src={node.acf.small_image.source_url}
        alt={node.title}
        className="border-radius-8px"
      />
    )}
  </div>
))}
                </div>
                <div className="swiper-pagination swiper-pagination-testimonial"></div>
              </div>

  );
};

export default TestimonialSlider;
