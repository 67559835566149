import React, { useEffect } from 'react';
import Swiper from 'swiper';
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';
import ReactHtmlParser from 'react-html-parser';

const WhatsNew = ({ whatsNewData }) => {
  useEffect(() => {
    // Initialize Swiper
    new Swiper('.whats-new-slide', {
      centeredSlides: false,
      loop: false,
      slidesPerView: 1,
      watchOverflow: true,
      autoplay: {
        delay: 9000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination-whats-new',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next-whats-new',
        prevEl: '.swiper-prev-whats-new',
      },
      breakpoints: {
        990: { slidesPerGroup: 3, slidesPerView: 3 },
        768: { slidesPerGroup: 2, slidesPerView: 2 },
        576: { slidesPerGroup: 1, slidesPerView: 1 },
      },
    });
  }, []);

  return (
    <div className="col-12 justify-content-center">
      <div className="whats-new-slide swiper-container swiper-style-new-design">
        <div className="swiper-wrapper">
          {whatsNewData &&
            whatsNewData.length > 0 &&
            whatsNewData.map((block, index) => (
              <div className="swiper-slide" key={index}>
                <div className="col-sm-12 col-12 col-lg-12 program-box-wrapper margin-20px-bottom padding-25px-lr">
                  <div className="whats-new-card blog-text">
                    <img
                      src={block.image.source_url}
                      alt={block.title}
                      className="whats-new-image"
                    />
                    {/* <div className="whats-new-tag   text-small font-weight-500 yellowbg alt-font text-white text-uppercase programlink">{block.tag}</div> */}
                    <div className="p-4">
                    <h3 className="whats-new-title mb-2">{block.title}</h3>
                   {ReactHtmlParser(block.description)}
                    <div className="whats-new-footer">
                     
                      <a
                        href={block.button_url}
                        className="whats-new-button"
                 
                        rel="noopener noreferrer"
                      >
                        {block.button_title}
                      </a>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="swiper-pagination swiper-pagination-whats-new"></div>
       
      </div>
    </div>
  );
};

export default WhatsNew;
