import React, { useState, useEffect } from 'react';

const InstagramReels = ({ instagramSectionTitle, instagramLink }) => {
  const [instagramPosts, setInstagramPosts] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch('https://theworldgrad.wpengine.com/wp-json/custom/v1/getreels')
      .then((response) => response.json())
      .then((data) => {
        // Check the structure of your data
        console.log(data); // Add this to inspect the data in the console

        // Map the posts to the correct format
        const imagePosts = data.map((post) => ({
          id: post.id,
          caption: post.caption,
          imageUrl: post.thumbnail_url || post.media_url, // Directly using media_url instead of thumbnail_url
          link: post.permalink,
        }));
        setInstagramPosts(imagePosts);
      })
      .catch((error) => console.error('Error fetching Instagram posts:', error));
  }, []);

  return (
    <section className="half-section">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 p-0 margin-two-bottom text-center wow animate__slideInLeft">
            <h2 className="color-blue">{instagramSectionTitle}</h2>
          </div>
        </div>
        <div className="row">
          {instagramPosts.map((post) => (
            <div className="col-md-4 mb-3" key={post.id}>
              <div className="instagram-post">
                <a href={post.link} target="_blank" rel="noopener noreferrer">
                  <img
                    className="w-100"
                    src={post.imageUrl}
                    alt={`Instagram Post ${post.id}`}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 text-center mt-5  watch-more">
            <a href={instagramLink} className="btn-style-new bg-orng-2">
              Watch More On{' '}
              <img
                src="https://assets.theworldgrad.com/wp-content/uploads/2023/07/instagram.png"
                alt="instagram"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstagramReels;
